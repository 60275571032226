import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    getConfig() {
        //console.log('TOKEN', store.getters.jwtToken);
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },
    slugify(value) {
        return services.http.post(`/v1.0/eventmanager/slugify`, qs.stringify({ value: value}), this.getConfig());
    },
    getOwnedEvents(userCreated, orgId) {
        return services.http.get(`/v1.0/eventmanager?userCreated=${userCreated||''}&orgId=${orgId||''}`, this.getConfig());
    },
    getRecentEvents() {
        return services.http.get(`/v1.0/eventmanager/recent`, this.getConfig());
    },
    create(model, userCreated) {
        return services.http.post(`/v1.0/eventmanager/?userCreated=${userCreated||''}`, model, this.getConfig());
    },
    get(id, includeSeries) {
        return services.http.get(`/v1.0/eventmanager/${id}?includeSeries=${includeSeries||''}`, this.getConfig());
    },
    put(model) {
        return services.http.put(`/v1.0/eventmanager/${model.id}`, model, this.getConfig());
    },
    getLicenseStatus(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/license`, this.getConfig());
    },
    getStats(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/stats`, this.getConfig());
    },
    getTasks(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/tasks`, this.getConfig());
    },
    getTask(id, taskId) {
        return services.http.get(`/v1.0/eventmanager/${id}/tasks/${taskId}`, this.getConfig());
    },
    addTrainingPlan(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/training-plans`, model, this.getConfig());
    },
    addStreakChallenge(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/generate/streak`, model, this.getConfig());
    },
    addLastManStandingChallenge(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/generate/lastmanstanding`, model, this.getConfig());
    },
    submitForApproval(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/submit`, model, this.getConfig());
    },
    linkWithSlack(id, slackChannelId) {
        return services.http.post(`/v1.0/eventmanager/${id}/link-slack?slackChannel=${slackChannelId}`, {}, this.getConfig());
    },
    linkWithTeams(id, teamsInfoJson) {
        return services.http.post(`/v1.0/eventmanager/${id}/link-teams?teamsInfoJson=${teamsInfoJson}`, {}, this.getConfig());
    },
    unlinkFromSlack(id) {
        return services.http.delete(`/v1.0/eventmanager/${id}/link-slack`, this.getConfig());
    },
    unlinkFromTeams(id) {
        return services.http.delete(`/v1.0/eventmanager/${id}/link-teams`, this.getConfig());
    },
    linkNext(id, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/link-next`, request, this.getConfig());
    },
    duplicate(id, newName) {
        return services.http.post(`/v1.0/eventmanager/${id}/duplicate`, qs.stringify({ newName: newName}), this.getConfig());
    },
    duplicateAsSimulation(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/create-simulator`, null, this.getConfig());
    },
    upload(id, type, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/upload/${type}`, model, this.getConfig());
    },
    uploadRaceGpx(id, raceId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/upload/gpx`, model, this.getConfig());
    },
    uploadRaceGeofence(id, raceId, geofencePoly) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/upload/geofence`, qs.stringify({ poly: geofencePoly}), this.getConfig());
    },
    uploadRaceResults(id, raceId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/upload/results`, model, this.getConfig());
    },
    uploadRegistrations(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/upload/registrations`, model, this.getConfig());
    },
    uploadPreloadedActivities(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/upload/preloadedactivities`, model, this.getConfig());
    },
    getPreloadedActivitiesInfo(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/preloadedactivities`, this.getConfig());
    },
    getRegistrations(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/registrations`, this.getConfig());
    },
    putRegistration(id, code, request) {
        return services.http.put(`/v1.0/eventmanager/${id}/registrations/${code}`, request, this.getConfig());
    },
    forceRegistrationSync(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/registrations/sync`, {}, this.getConfig());
    },
    getParticipants(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/participants`, this.getConfig());
    },
    getParticipant(id, profileId) {
        return services.http.get(`/v1.0/eventmanager/${id}/participants/${profileId}`, this.getConfig());
    },
    putParticipant(id, profileId, raceIds) {
        return services.http.put(`/v1.0/eventmanager/${id}/participants/${profileId}`, qs.stringify({ raceIds: raceIds}), this.getConfig());
    },
    requestParticipantProviderSync(id, profileId, provider, days) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/connections/${provider}/sync?daysInPast=${days}`, null, this.getConfig());
    },
    getProfile(id, profileId) {
        return services.http.get(`/v1.0/eventmanager/${id}/profiles/${profileId}`, this.getConfig());
    },
    disconnectParticipant(id, profileId, deleteResults) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/disconnect`, qs.stringify({ deleteResults: deleteResults}), this.getConfig());
    },
    reprocessParticipant(id, profileId) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/reprocess`, {}, this.getConfig());
    },
    syncTeamsForParticipant(id, profileId) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/syncteams`, {}, this.getConfig());
    },
    getProfileActivities(id, profileId, continuationToken) {
        //return services.http.get(`/v1.0/eventmanager/${id}/participants/${profileId}/activities`, this.getConfig());
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/paged`, qs.stringify({continuationToken:continuationToken}), this.getConfig());
    },
    getParticipantActivityDetails(id, profileId, activityId) {
        return services.http.get(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}`, this.getConfig());
    },
    addParticipantActivity(id, profileId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/activities`, model, this.getConfig());
    },
    editParticipantActivity(id, profileId, activityId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}`, model, this.getConfig());
    },
    deleteParticipantActivity(id, profileId, activityId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}`, this.getConfig());
    },
    approveParticipantActivityDetails(id, profileId, activityId, raceId, badgeId) {
        return services.http.post(`/v1.0/eventmanager/${id}/participants/${profileId}/activities/${activityId}/approve/${raceId}?badgeId=${badgeId||''}`, this.getConfig());
    },
    deleteResult(id, raceId, resultId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/${resultId}/disqualify`, {}, this.getConfig());
    },
    recalculateResult(id, raceId, resultId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/${resultId}/recalculate`, {}, this.getConfig());
    },
    deleteProfileFromTeamResult(id, raceId, resultId, profileId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/${raceId}/${resultId}/profiles/${profileId}`, this.getConfig());
    },
    getGroupParticipants(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/groupparticipants`, this.getConfig());
    },
    createTeam(id, teamName) {
        return services.http.post(`/v1.0/eventmanager/${id}/groupparticipants`, qs.stringify({name:teamName}), this.getConfig());
    },
    getJoinInfo(id, code) {
        return services.http.get(`/v1.0/eventmanager/${id}/joininfo?code=${code||''}`, this.getConfig());
    },
    getAllowlist(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/allowlist?raceId=${raceId||''}`, this.getConfig());
    },
    putAllowlist(id, raceId, data) {
        return services.http.put(`/v1.0/eventmanager/${id}/allowlist?raceId=${raceId||''}`, qs.stringify({ allowlist: data}), this.getConfig());
    },
    getDenylist(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/denylist`, this.getConfig());
    },
    releaseCode(id, code) {
        return services.http.delete(`/v1.0/eventmanager/${id}/denylist/${code||''}`, this.getConfig());
    },

    getPermissions(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/permissions`, this.getConfig());
    },
    addPermissions(id, profileId) {
        return services.http.put(`/v1.0/eventmanager/${id}/permissions/${profileId}`, {}, this.getConfig());
    },
    deletePermissions(id, profileId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/permissions/${profileId}`, this.getConfig());
    },

    exportReport(id, report) {
        return services.http.get(`/v1.0/eventmanager/${id}/export/${report}`, { ...this.getConfig(), responseType: 'blob' });
    },
    exportRaceReport(id, raceId, report) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/export/${report}`, { ...this.getConfig(), responseType: 'blob' });
    },
    sendRaceCompletedEmail(id, raceId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/email/completed`, {}, this.getConfig());
    },
    sendTestEmail(relativeUrl) {
        return services.http.post(`/v1.0/eventmanager/${relativeUrl}`, {}, this.getConfig());
    },
    sendRaceCertificateEmail(id, raceId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/email/certificate`, {}, this.getConfig());
    },
    sendBibEmail(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/bib`, {}, this.getConfig());
    },
    sendInviteEventEmail(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/invite`, {}, this.getConfig());
    },
    sendFriendInviteEmail(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/friendinvite`, {}, this.getConfig());
    },
    sendInviteEventEmailToCode(id, code) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/invite/${code}`, {}, this.getConfig());
    },
    reinviteUnconnected(id, code) {
        return services.http.post(`/v1.0/eventmanager/${id}/email/invite/unconnected`, {}, this.getConfig());
    },

    moderatePhoto(id, photoId, action) {
        return services.http.post(`/v1.0/eventmanager/${id}/photos/${photoId}/${action}`, this.getConfig());
    },
    putPhotoOverlays(id, overlays) {
        return services.http.put(`/v1.0/eventmanager/${id}/overlays`, overlays, this.getConfig());
    },
    uploadPhotoOverlay(id, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/overlays/image`, model, this.getConfig());
    },

    getFeed(id, type, continuationToken) {
        return services.http.get(`/v1.0/eventmanager/${id}/feed?type=${type||''}&continuationToken=${encodeURIComponent(continuationToken||'')}`, this.getConfig());
    },
    postToFeed(id, model, sendPushMessage, raceId) {
        return services.http.post(`/v1.0/eventmanager/${id}/feed?sendPushMessage=${sendPushMessage||'false'}&raceId=${raceId||''}`, model, this.getConfig());
    },
    editFeedItem(id, feedItemId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/feed/${feedItemId}`, model, this.getConfig());
    },
    deleteFeedItem(id, feedItemId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/feed/${feedItemId}`, this.getConfig());
    },
    moderateFeedItem(id, feedItemId, action) {
        return services.http.post(`/v1.0/eventmanager/${id}/feed/${feedItemId}/${action}`, model, this.getConfig());
    },

    getOAuthClient(id, provider) {
        return services.http.get(`/v1.0/eventmanager/${id}/oauthclients/${provider}`, this.getConfig());
    },
    putOAuthClient(id, provider, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/oauthclients/${provider}`, model, this.getConfig());
    },

    getActivityConversions(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/conversions`, this.getConfig());
    },
    putActivityConversions(id, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/conversions`, model, this.getConfig());
    },

    putWebhooks(id, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/webhooks`, model, this.getConfig());
    },

    getBibPreview(id, participantId) {
        return services.http.get(`/v1.0/eventmanager/${id}/bib/preview?participantId=${participantId||''}`, { ...this.getConfig(), responseType: 'blob' });
    },

    getCertificatePreview(id, raceId, resultId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/certificate/preview?resultId=${resultId||''}`, { ...this.getConfig(), responseType: 'blob' });
    },

    getRelaySlots(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/relayslots`, this.getConfig());
    },
    putRelaySlots(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/relayslots`, model, this.getConfig());
    },

    getResultColumns(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/columns`, this.getConfig());
    },
    putResultColumns(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/columns`, model, this.getConfig());
    },
    cloneResultColumns(id, raceId, raceIds) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/columns/clone`, qs.stringify({ raceIds: raceIds}), this.getConfig());
    },

    getBadges(id, raceId) {
        return services.http.get(`/v1.0/eventmanager/${id}/${raceId}/badges`, this.getConfig());
    },
    putBadge(id, raceId, model) {
        return services.http.put(`/v1.0/eventmanager/${id}/${raceId}/badges`, model, this.getConfig());
    },
    previewBadge(id, raceId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/preview`, model, this.getConfig());
    },
    deleteBadge(id, raceId, badgeId) {
        return services.http.delete(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}`, this.getConfig());
    },
    sendBadgeEmail(id, raceId, badgeId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}/email`, {}, this.getConfig());
    },
    uploadBadgeImage(id, raceId, badgeId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}/image`, model, this.getConfig());
    },
    uploadBadgeDetailsImage(id, raceId, badgeId, model) {
        return services.http.post(`/v1.0/eventmanager/${id}/${raceId}/badges/${badgeId}/detailsimage`, model, this.getConfig());
    },
    copyBadges(id, fromRaceId, toRaceId) {
        return services.http.post(`/v1.0/eventmanager/${id}/${fromRaceId}/badges/copy?toRaceId=${toRaceId}`, {}, this.getConfig());
    },

    integrationsConfigureApp(id, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/app`, request, this.getConfig());
    },
    integrationsEventsComEvents(id) {
        return services.http.get(`/v1.0/eventmanager/${id}/integrations/eventscom/events`, this.getConfig());
    },
    integrationsEventsComConnect(id, eventsComProductId) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/eventscom/events/${eventsComProductId}`, {}, this.getConfig());
    },
    integrationsEventsComSync(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/eventscom/sync`, {}, this.getConfig());
    },
    integrationsClassySync(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/classy/sync`, {}, this.getConfig());
    },
    integrationsMiroConnect(id, request) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/miro/connect`, request, this.getConfig());
    },
    integrationsTeamsSync(id) {
        return services.http.post(`/v1.0/eventmanager/${id}/integrations/teams/sync`, {}, this.getConfig());
    },
    integrationsTeamsVerify(teamsInfo) {
        return services.http.post(`/v1.0/eventmanager/integrations/teams/validate`, teamsInfo, this.getConfig());
    },

    getAds() {
        return services.http.get(`/v1.0/eventmanager/ads`, this.getConfig());
    },
    postAd(model) {
        return services.http.post(`/v1.0/eventmanager/ads`, model, this.getConfig());
    },
    putAd(adId, model) {
        return services.http.put(`/v1.0/eventmanager/ads/${adId}`, model, this.getConfig());
    },
    deleteAd(adId) {
        return services.http.delete(`/v1.0/eventmanager/ads/${adId}`, this.getConfig());
    },
    uploadAdImage(adId, model) {
        return services.http.post(`/v1.0/eventmanager/ads/${adId}/image`, model, this.getConfig());
    },

}