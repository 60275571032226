<template>
    <div>
      <v-btn-toggle v-if="!raceId" v-model="activityType" mandatory>
        <v-btn v-for="item in activityTypeItems" :key="item.type" :value="item.type">
          <v-icon class="mr-2">{{$helpers.getActivityIcon(item.type)}}</v-icon>
          {{ $t('profile.activities.types.'+item.type) }}
        </v-btn>
      </v-btn-toggle>
      <br/><br/>
      <v-file-input accept="*/xml" :label="$t('profile.activities.entry.select-gpx-file')" v-model="file" v-on:change="fileSelected"></v-file-input>
      <v-alert v-if="!raceId && syncResult && syncResult.status == 'OK'" type="success" prominent>
        <v-row align="center">
          <v-col class="grow">{{$t('profile.activities.entry.uploaded-success')}}</v-col>
          <v-col class="shrink">
            <v-btn :to="{name:'activities'}">{{$t('shared.view')}}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'OK'" type="success"><strong>All good! Track map is saved.</strong></v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'INFO'" type="info"><strong>{{ syncResult.msg }}</strong></v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'ERROR'" type="error">Unfortunately, we couldn't process your file: <strong>{{ syncResult.msg }}</strong></v-alert>
      <v-btn color="primary" outlined @click="uploadGpx" :loading="$store.getters.isLoading">{{$t('shared.upload-file')}}</v-btn>
    </div>
</template>

<script>

import siteData from '@/data/site.json'
import activityService from "@/services/activityService";
import eventManagerService from "@/services/eventManagerService";

export default {
  name: "ActivityUploadForm",
  props: {
    eventId: String,
    raceId: String,
  },
  data: function() {
    return {
      file: null,
      activityType: null,
      activityTypeItems: siteData.activities.filter(x => x.core),
      syncResult: null,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async fileSelected() {
      this.syncResult = null;
    },

    async uploadGpx() {
      this.syncResult = null;
      let formData = new FormData()
      if (this.file) {
        formData.append("file", this.file, this.file.name);
        formData.append("eventId", this.eventId);
        formData.append("raceId", this.raceId);
        formData.append("type", this.activityType);
        if (this.eventId && this.raceId) {
          this.syncResult = (await eventManagerService.uploadRaceGpx(this.eventId, this.raceId, formData)).data;
        }
        else {
          var response = await activityService.upload(formData);
          this.syncResult = response.data;
        }
      }
      else {
        this.syncResult = {
          status: "ERROR",
          msg: "Please select a file to upload."
        };
      }
    },
  },
  computed: {
  },
};
</script>

<style scoped>
</style>